import App from './App';
import React from 'react';
import { HttpService, createContainer } from '@datr-browser/components';
import ReactDOM from 'react-dom';

import './index.css';

(async () => {
    let http = new HttpService();
    // let applicationSetup = await http.get(`/ui/applications/reservations/setup`);
    createContainer({} as any);

    ReactDOM.render(<App />, document.getElementById('root'));
})();
