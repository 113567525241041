import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import {
	AppBar,
	Button,
	CircularProgress,
	Grid,
	TextField,
	ThemeProvider,
	Typography,
	Link,
	InputLabel
} from '@material-ui/core';
import {
	Checkbox,
	Container,
	getTheme,
	Select,
	TextBox
} from '@datr-browser/components';
import { IKeyValueRecord } from '@datr-browser/components/api';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';

import 'moment/locale/cs';

function App() {
	const theme = getTheme();
	theme.palette.primary.main = '#d60402';
	theme.palette.secondary.main = '#333';

	let [dataState, setDataState] = useState(Container.createDataStateService());

	const [success, setSuccess] = useState(false);
	const [notReady, setNotReady] = useState(true);
	const [submiting, setSubmiting] = useState(false);
	const [dateOptions, setDateOptions] = useState({
		open: false,
		disabled: true,
		items: []
	});

	const [timeOptions, setTimeOptions] = useState({
		open: false,
		disabled: true,
		items: []
	});

	const openDates = async () => {
		let dates = await Container.http.get(`/reservations/dates?${qs.stringify({
			hasSets: dataState.record.hasSets,
			dimensions: dataState.record.dimensions,
			runflat: dataState.record.runflat
		})}`);

		setDateOptions({
			...dateOptions,
			items: dates,
			open: true
		});
	};

	const openTimes = async () => {
		let times = await Container.http.get(`/reservations/times?${qs.stringify({
			hasSets: dataState.record.hasSets,
			dimensions: dataState.record.dimensions,
			selectedDate: dataState.record.selectedDate, // dataState.record.selectedDate.key,
			runflat: dataState.record.runflat
		})}`);

		setTimeOptions({
			...timeOptions,
			items: times.map((x: any) => ({
				label: x.label,
				key: x.label
			})),
			open: true
		});
	};

	const getSelectedTime = () => {
		if (!dataState.record.selectedTime?.key) {
			return null;
		}

		let time = dataState.record.selectedTime.key.split(':');
		return moment(dataState.record.selectedDate).set({
			hours: time[0],
			minutes: time[1]
		});
	};

	const doSubmit = async () => {
		setSubmiting(true);

		try {
			let result = await Container.http.post('/reservations/reserve', {
				...dataState.record,
				selectedDate: dataState.record.selectedDate, // dataState.record.selectedDate.key,
				selectedTime: getSelectedTime()
			});
			setSuccess(true);
		} catch (error) {
			setSubmiting(false);

			if (error.status == 400) {
				dataState.emitValidationErrors(error.serverError.errors);
			} else {
				alert('Něco se porouchalo :( Zkuste to prosím později.')
			}
		}
	};

	useEffect(() => {
		dataState.set('selectedDate', new Date());
		dataState.pathChange
			.subscribe(changeEvent => {
				let values = dataState.record;

				if (['hasSets', 'dimensions', 'runflat', 'selectedDate', 'selectedTime'].indexOf(changeEvent.path) > -1) {
					if (['hasSets', 'dimensions', 'runflat'].indexOf(changeEvent.path) > -1) {
						if (values.hasSets && values.dimensions) {
							setDateOptions({ ...dateOptions, disabled: false });
						} else {
							setDateOptions({ ...dateOptions, disabled: true });
						}

						// dataState.set('selectedDate', null);
						dataState.set('selectedTime', null);
					}

					if (changeEvent.path == 'selectedDate') {
						dataState.set('selectedTime', null);
					}

					if (values.selectedDate && values.hasSets && values.dimensions) {
						setTimeOptions({ ...timeOptions, disabled: false });
					} else {
						setTimeOptions({ ...timeOptions, disabled: true });
					}

					console.log(values);
					if (values.selectedDate && values.selectedTime) {
						setNotReady(false);
					} else {
						setNotReady(true);
					}
				}
			});
	}, []);

	let form = (
		<React.Fragment>
			<Typography variant="h5">Pohodlná rezervace termínu návštěvy Pneuservisu ADF.</Typography>
			<div style={{ fontSize: '13px', marginBottom: '30px' }}>
				Prostřednictví níže uvedeného online formuláře si můžete objednat přezutí svého vozidla pro SIXT ČR. Pro dokončení rezervace prosíme o vyplnění všech údajů.
			</div>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<TextBox control={{ label: 'Jméno a příjmení', path: 'reservation.clientName' } as any} dataState={dataState}></TextBox>
				</Grid>
				<Grid item xs={6}>
					<TextBox control={{ label: 'SPZ', path: 'reservation.licencePlate' } as any} dataState={dataState}></TextBox>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<TextBox control={{ label: 'E-mail', path: 'reservation.clientEmail' } as any} dataState={dataState}></TextBox>
				</Grid>
				<Grid item xs={6}>
					<TextBox control={{ label: 'Telefon', path: 'reservation.clientPhone' } as any} dataState={dataState}></TextBox>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<Select control={{
						label: 'Výběr služby',
						path: 'hasSets',
						items: [
							{ label: 'Přezutí pneumatik', key: 'TireChange' },
							{ label: 'Výměnu kompletních kol', key: 'SetChange' }
						]
					} as any} dataState={dataState}></Select>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Select control={{
						label: 'Vyberte rozměry kol',
						path: 'dimensions',
						items: [
							{ label: 'do 17"', key: 'Tire18AndLower' },
							{ label: '18" a více', key: 'Tire19AndGreater' }
						]
					} as any} dataState={dataState}></Select>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Checkbox control={{ label: 'Mám Run-Flatové (dojezdové) pneumatiky', path: 'runflat' } as any} dataState={dataState}></Checkbox>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<div style={{
							maxWidth: '325px',
							borderRadius: '4px',
							overflow: 'hidden',
							border: '1px solid rgba(196, 196, 196, 0.5)',
							margin: '0 auto'
						}}>
							<DatePicker
								variant="static"
								disabled={dateOptions.disabled}
								onChange={date => dataState.set('selectedDate', date.toDate())}
								value={dataState.record.selectedDate || null}></DatePicker>
						</div>
					</MuiPickersUtilsProvider>
					{/* <Autocomplete<IKeyValueRecord>
						open={dateOptions.open}
						value={dataState.record.selectedDate || null}
						onOpen={() => openDates()}
						onClose={() => setDateOptions({ ...dateOptions, open: false })}
						getOptionSelected={(option, value) => option.key === value}
						getOptionLabel={option => option.label}
						options={dateOptions.items}
						disabled={dateOptions.disabled}
						noOptionsText="Žádné volné termíny"
						onChange={(event, value: IKeyValueRecord) => dataState.set('selectedDate', value)}
						renderInput={params => (
							<TextField
								{...params}
								label="Termín"
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									shrink: true
								}}
							/>
						)}
					/> */}
				</Grid>
				<Grid item xs={12} md={6}>
					<Autocomplete<IKeyValueRecord>
						open={timeOptions.open}
						value={dataState.record.selectedTime || null}
						onOpen={() => openTimes()}
						onClose={() => setTimeOptions({ ...timeOptions, open: false })}
						getOptionSelected={(option, value) => option.key === value}
						getOptionLabel={option => option.label}
						options={timeOptions.items}
						disabled={timeOptions.disabled}
						noOptionsText="Žádné volné termíny"
						onChange={(event, value: IKeyValueRecord) => dataState.set('selectedTime', value)}
						renderInput={params => (
							<TextField
								{...params}
								label="Čas návštěvy"
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									shrink: true
								}}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<p>
						<small>Stisknutím tlačítka rezervovat souhlasíte s <Link href="https://www.tirextyre.cz/obchodni-podminky" target="blank">obchodními podmínkami</Link> a podmínkami ochrany osobních údajů.</small>
					</p>
					<Button variant="contained" disabled={notReady || submiting} onClick={() => doSubmit()} color="primary" size="large" fullWidth={true}>
						{submiting ? <CircularProgress /> : "Rezervovat"}
					</Button>
				</Grid>
			</Grid>
			<p style={{ color: 'grey' }}>
				<small>Adresa: ADF PNEUSERVIS - TireLogistics, s.r.o, Logistická 107, 273 51 Pavlov, okres Kladno</small><br />
				<small>Tel: +420 702 100 959</small><br />
				<small>E-mail: adf.pavlov@gmail.com</small>
			</p>
		</React.Fragment>
	);

	let selectedTime = getSelectedTime();
	let successMessage = (
		<Alert severity="success">
			<AlertTitle>Rezervace proběhla v pořádku</AlertTitle>
			{
				selectedTime &&
				`Vaše rezervace na termín ${moment(selectedTime).format('D.M.YYYY HH:mm')} byla přijata.`
			}
		</Alert>
	);

	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static">
				<Typography variant="h6">
					<img src="/logo.png" style={{ margin: '0 20px' }} />
				</Typography>
			</AppBar>
			<div style={{ maxWidth: '800px', margin: '20px auto 0 auto', padding: '0 20px' }}>
				{success ? successMessage : form}
			</div>
		</ThemeProvider>
	);
}

export default App;
